import React, { useCallback } from 'react';
import './fonts.css'
import './App.css';
import { Box, Button, CircularProgress, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { parse as parseCSV, stringify as stringifyCSV } from 'csv'
import { stripLegalAbbreviations } from './legal';
import { getGender } from 'gender-detection-from-name';

type UnformattedLead = {
  "t-highlight-text": string
  "text-fadeout": string
  haOyaKf: string
  "text-fadeout 2": string
  "d-flex href": string
  "skeleton-bar href 2": string
  "t-image src": string
  "t-highlight-text 2": string
  "d-flex 2": string
  "t-highlight-text 3": string
  "cursor-pointer href": string
  "cursor-pointer href 2": string
  "text-fadeout 3": string
  "text-fadeout href": string
  "link href": string
  "eb-icon href 4": string
  "text-gray-400 href": string
  "text-gray-400 href 2": string
  "eb-icon href 6": string
  "haOyaKf 3": string
  "eb-icon href 8": string
}

type FormattedLead = {
  fullName: string
  likelyGender: 'female' | 'male' | null
  salutation: string | null
  firstName: string
  lastName: string
  department: string
  hierachy: string
  companyName: string
  cleanLemlist: string
  cleanedCompanyName: string
  position: string
  phone: string
  email: string
  companyDomain: string
  domainCounter: number
}


const steps = [
  "Upload Leads",
  "Cleaning Leads",
  "Guessing genders",
  "Rebuilding CSV",
  "Done",
]

const parseCSVAsync = async (file: File): Promise<any[] | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
  reader.onload = (event) => {
    const content = event.target?.result;
    if(!content) {
      resolve(null)
      return;
    }
    const contentString = typeof content === 'string' ? content : new TextDecoder().decode(content);
    const parsed = parseCSV(
      contentString,
      {
        columns: true,
        skip_empty_lines: true,
      },
      (error, data) => {
        if(error) {
          resolve(null)
        } else {
          resolve(data)
        }
      }
    )
    return parsed;
  }
  reader.readAsText(file);
  })
}

const stringifyCSVAsync = async (leads: FormattedLead[]): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    stringifyCSV(
      leads,
      {
        header: true,
      },
      (error, data) => {
        if(error) {
          resolve(null)
        } else {
          resolve(data)
        }
      }
    )
  })
}

const delay = async (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const remapFields = async (data: UnformattedLead[]): Promise<FormattedLead[]> => new Promise((resolve) => {
  const companyDomains: Record<string, number> = {}

  const remapped: FormattedLead[] = data.map((lead) => {

    const fullName = lead["t-highlight-text"]
    const firstName = fullName.split(' ')[0]
    const lastName = fullName.split(' ')[1]

    const department = lead["text-fadeout"]
    const hierachy = lead["text-fadeout 2"]
    const companyName = lead["t-highlight-text 2"]
    const position = lead["t-highlight-text 3"]

    const phone = (lead["cursor-pointer href"] || lead["cursor-pointer href 2"]).replace('tel:', '')
    const email = lead['text-fadeout 3'] || lead["text-fadeout href"].replace('mailto:', '')
    const companyDomain = `@${email.split('@')[1]}`
    
    // Domain counter
    if(companyDomains[companyDomain]) {
      companyDomains[companyDomain] += 1
    } else {
      companyDomains[companyDomain] = 1
    }
    const domainCounter = companyDomains[companyDomain]

    // Cleaned company name
    // According to Lemlist, this removes emojis and legal entities
    const cleanLemlist = stripLegalAbbreviations(
      companyName
      // Remove emojis 
      .replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')
    )
    // Not 100% sure why we even need this one, it *should* be the same anyways
    const cleanedCompanyName = cleanLemlist


    return {
      fullName,
      likelyGender: null,
      salutation: null,
      firstName,
      lastName,
      department,
      hierachy,
      companyName,
      cleanLemlist,
      cleanedCompanyName,
      position,
      phone,
      email,
      companyDomain,
      domainCounter,
    }
  })
  resolve(remapped)
})

const addGenders = async (leads: FormattedLead[]): Promise<FormattedLead[]> => new Promise((resolve) => {
  const gendered = leads.map((lead) => {
    const likelyGender = getGender(lead.firstName, 'de') as 'female' | 'male'
    return {
      ...lead,
      likelyGender,
      salutation: likelyGender === 'male' ? 'Herr' : 'Frau',
    }
  })
  resolve(gendered)
})



function App() {
  const { enqueueSnackbar } = useSnackbar()

  const [currentStep, setCurrentStep] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [fileName, setFileName] = React.useState<string | null>(null)
  const [transformedCSV, setTransformedCSV] = React.useState<string | null>(null)
  const [resetAllowed, setResetAllowed] = React.useState(false)

  // File-ops
  const transformFile = useCallback(async (file: File) => {
    setTransformedCSV(null)

    // Load file and parse
    const data = await parseCSVAsync(file);
    if(!data) {
      enqueueSnackbar('Error parsing file', { variant: 'error' });
      return;
    }
    
    // Rename and remove fields
    setCurrentStep(1);
    const [remapped, _a] = await Promise.all([
      remapFields(data as UnformattedLead[]),
      delay(1000)
    ]);
    
    // Guess the Genders
    setCurrentStep(2);
    const [gendered, _b] = await Promise.all([
      addGenders(remapped),
      delay(1000)
    ]);

    // Rebuild CSV
    setCurrentStep(3);
    const [rebuilt, _c] = await Promise.all([
      stringifyCSVAsync(gendered),
      delay(1000)
    ]);
    if(!rebuilt) {
      enqueueSnackbar('Error rebuilding CSV', { variant: 'error' });
      return;
    }
    setTransformedCSV(rebuilt);
    setLoading(false);

    // Done
    setCurrentStep(4);

  }, [ enqueueSnackbar ])

  // Upload
  const uploadInputRef = React.useRef<HTMLInputElement>(null)
  const handleUploadButtonClick = useCallback(() => {
    uploadInputRef.current?.click()
    setLoading(true)
  }, [])
  const handleFileUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if(!files) {
      enqueueSnackbar('No file selected', { variant: 'error' });
      return;
    } 
    if(files.length > 1) {
      enqueueSnackbar('Please select only one file', { variant: 'error' });
      return;
    }

    const file = event.target.files?.[0]
    if (file) {
      setFileName(file.name)
      transformFile(file);
    }
  }, [ enqueueSnackbar, transformFile ])

  const handleDownload = useCallback(() => {
    if(transformedCSV && fileName) {
      const blob = new Blob([transformedCSV], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${fileName?.split('.')[0]}_cleaned.csv`
      a.click()
    } else {
      if(!fileName) enqueueSnackbar('Something went wrong, no filename', { variant: 'error' });
      if(!transformedCSV) enqueueSnackbar('Something went wrong, no CSV', { variant: 'error' });
    }
    setResetAllowed(true)
  }, [ transformedCSV, fileName, enqueueSnackbar])

  const resetTool = useCallback(() => {
    setFileName(null)
    setTransformedCSV(null)
    setCurrentStep(0)
    setResetAllowed(false)
  }, [])

  return (
    <Paper sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100vw',
      height: '100vh',
      pt: 4,
    }}>
      <Typography variant='h3'>
        Lead cleaner
      </Typography>

      <input
        type='file'
        className='hidden'
        ref={uploadInputRef}
        accept='.csv'
        onChange={handleFileUpload}
      />

      <Box my='auto'>
        {currentStep === 0 && (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 4,
            mb: 4,
            maxWidth: 400,
            mx: 'auto'
          }}>
            <Typography variant='h4' sx={{
              mb: 4,
              width: '100%',
            }}>
              Upload your leads
            </Typography>

            <Typography variant='body1' sx={{
              width: '100%',
              height: 200,
            }}>
              Upload the raw CSV file from the Scraper <br />
              I don't know the name, but the "Pokeball" 
              </Typography>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 4,
            }}>
              <LoadingButton
                loading={loading}
                variant='contained'
                color='primary'
                onClick={handleUploadButtonClick}
              >
                Upload CSV
              </LoadingButton>
            </Box>
          </Box>
        )}
        {currentStep === 1 && (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 4,
            mb: 4,
            maxWidth: 400,
            mx: 'auto'
          }}>
            <Typography variant='h4' sx={{
              mb: 4,
              width: '100%',
            }}>
              Cleaning Leads
            </Typography>
            <Typography variant='body1' sx={{
              width: '100%',
              height: 100,
            }}>
              Removing uneccessary fields <br />
              Renaming others <br />
              Cleaning company names, removing emojis and legal entities
            </Typography>
            <Box sx={{
              display: 'flex',
              height: 100,
            }}>
              <CircularProgress variant='indeterminate' sx={{ m: 'auto' }} />
            </Box>
          </Box>
        )}
        {currentStep === 2 && (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 4,
            mb: 4,
            maxWidth: 400,
            mx: 'auto'
          }}>
            <Typography variant='h4' sx={{
              mb: 4,
              width: '100%',
            }}>
              Guessing Genders
            </Typography>
            <Typography variant='body1' sx={{
              width: '100%',
              height: 100,
            }}>
              Guessing genders based on first names <br/>
              (Assuming that all people are from Germany) <br/>
              Adding salutations <br />
            </Typography>
            <Box sx={{
              display: 'flex',
              height: 100,
            }}>
              <CircularProgress variant='indeterminate' sx={{ m: 'auto' }} />
            </Box>
          </Box>
        )}
        {currentStep === 3 && (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 4,
            mb: 4,
            maxWidth: 400,
            mx: 'auto'
          }}>
            <Typography variant='h4' sx={{
              mb: 4,
              width: '100%',
            }}>
              Rebuilding CSV
            </Typography>
            <Typography variant='body1' sx={{
              width: '100%',
              height: 100,
            }}>
                Rebuilding the CSV with the new data
            </Typography>
            <Box sx={{
              display: 'flex',
              height: 100,
            }}>
              <CircularProgress variant='indeterminate' sx={{ m: 'auto' }} />
            </Box>
          </Box>
        )}
        {currentStep === 4 && (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 4,
            mb: 4,
            maxWidth: 400,
            mx: 'auto'
          }}>
            <Typography variant='h4' sx={{
              mb: 4,
              width: '100%',
            }}>
              Done
            </Typography>
            <Typography variant='body1' sx={{
              width: '100%',
              height: 200,
            }}>
                Your file is ready for download
              </Typography>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 4,
            }}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleDownload}
              >
                Download CSV
              </Button>
              <Button
                disabled={!resetAllowed}
                variant='outlined'
                color='secondary'
                onClick={resetTool}
              >
                Reset
              </Button>
            </Box>
          </Box>
        )}

        <Stepper activeStep={currentStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Paper>
  );
}

export default App;
