import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        mode: 'dark',
    },
    typography: {
        fontFamily: '"IBM Plex Mono", monospace',
        h1: {
            fontFamily: '"Montserrat", sans-serif',
        },
        h2: {
            fontFamily: '"Montserrat", sans-serif',
        },
        h3: {
            fontFamily: '"Montserrat", sans-serif',
            fontWeight: 700,
        },
        h4: {
            fontFamily: '"Montserrat", sans-serif',
            fontWeight: 700,
        },
        h5: {
            fontFamily: '"Montserrat", sans-serif',
        },
        h6: {
            fontFamily: '"Montserrat", sans-serif',
        },

    },
})